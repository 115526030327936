import { Button, IconButton } from '@material-ui/core';
import { Stack, Grid, Box } from '@mui/material';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Account,
  AccountsState,
  ContactCf,
  Project,
} from 'Redux/Reducers/opportunity/accounts.reducer';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  CustomField,
  CustomFields,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { useSelector } from 'react-redux';
import { formatDateField, separateByComma } from 'helpers/common';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import { Contact } from 'Redux/Reducers/opportunity/contacts.reducer';
import OpportunityAccountsCustomFieldsAPI from 'Redux/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import ContactOrAccountDetailsSideModal from './ContactOrAccountDetailsSideModal';
import ReadMore from '../../../assets/images/icons/informationIcon.svg';
import { getSystemLevelPermissions } from '../../../helpers/common';
import { createNotification } from '../../../helpers';
import moment from 'moment';

type Props = {
  closeViewModal: () => void;
  accountToBeEdited: Account | undefined;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
  commentSectionOpened: boolean;
};

function AccountView({
  closeViewModal,
  accountToBeEdited,
  setShowAddNew,
  setShowViewModal,
  handleTabChange,
  commentSectionOpened
}: Props) {
  const { isLoading: isAccountsLoading } = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const [openSideModal, setOpenSideModal] = useState(false);
  const [allowed, setAllowed] = useState<any>({});
  const [titleForSideModal, setTitleForSideModal] = useState<string>('');
  const [contactDetailsForToolTip, setContactDetailsForToolTip] =
    useState<Contact>({} as Contact);
  const [contactsCustomFields, setContactsCustomFields] =
    useState<CustomFields>({} as CustomFields);

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    fetchContactsCustomField();
  }, []);

  const fetchContactsCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'contacts',
        from: 'viewAccount',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setContactsCustomFields(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowSideModal = (contactId: string, title: string) => {
    setTitleForSideModal(title);
    setOpenSideModal(true);
    fetchContactById(contactId);
  };

  const fetchContactById = async (contactId: string) => {
    try {
      const opportunityContactsAPI = new OpportunityContactsAPI();
      const res = await opportunityContactsAPI.fetchOpportunityContact({
        id: contactId,
      });
      if (res.data?.contact) {
        setContactDetailsForToolTip(res.data.contact);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSideModal = () => {
    setOpenSideModal(false);
  };

  const showRestOfPages = (cfs: (string | { email: string; })[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const customFieldValue = (
    cf: CustomField,
    accountToBeEdited: Account | undefined,
  ) => {
    const value1 = accountToBeEdited?.customFields?.[cf._id as string] as any | any[];
    if (cf.fieldData.fieldType === 'contact') {
      return (
        (accountToBeEdited?.customFields?.[cf._id as string]?.fullName as string) || '-'
      );
    } else if (cf.fieldData.fieldType === 'boolean') {
      return accountToBeEdited?.customFields?.[cf._id as string]?.toString() === "true"
        ? "True"
        : "False";
    } else if (cf.fieldData.fieldType === 'user' && value1?.length > 0) {
      return (
        <>
          {/* Display the first email (or value) */}
          <span
            className={`long-text ${cf.fieldData.fieldType === 'user' && 'link'}`}
            style={{ maxWidth: '80%' }}
            onClick={() => {
              if (cf.fieldData.fieldType === 'user' &&  value1 && value1?.length > 0) {
                window.open(`mailto:${ value1 && value1[0]?.email || value1[0]}`);
              }
            }}
          >
            {cf.fieldData.fieldType === 'user'
              ? value1[0]?.email || value1[0]
              : value1[0]}
          </span>

          {/* If there are more emails, display a popup with the rest */}
          { value1 && value1?.length > 1 && (
            <div className="detail-popup">
              <span className="count">+{ value1 && value1?.length - 1}</span>
              <div className="info">
                {showRestOfPages(value1).map((cfValue: string | { email: string; }, i: number) => {
                  const isObject = typeof cfValue === 'object' && cfValue !== null;
                  const displayValue = isObject ? (cfValue as { email: string; }).email : cfValue;

                  return (
                    <div
                      key={i}
                      className={cf.fieldData.fieldType === 'user' ? 'link' : ''}
                      style={{ zIndex: 999999 }}
                      onClick={() => {
                        if (cf.fieldData.fieldType === 'user' && isObject) {
                          window.open(`mailto:${displayValue}`);
                        }
                      }}
                    >
                      <p>{displayValue}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      );
    } else {
      return cf.fieldData.fieldType === 'date'
        ? formatDateField(accountToBeEdited, cf, customFields)
        : accountToBeEdited?.customFields?.[cf._id as string] || '';
    }
  };


  const showRestOfProjects = (projects: Project[]) => {
    let projectsLength = projects.length;
    return projects.slice(1, projectsLength);
  };

  return (
    <>
      <Box className="Opportunity-Account"  >
        {/* @ts-ignore */}
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton size="small" onClick={closeViewModal}>
                <KeyboardBackspaceIcon />
              </IconButton>
              <Box className="paragraph bold">Account View</Box>
            </Stack>
          </Grid>
          <Grid item>
            {/* @ts-ignore */}
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid item>
                {allowed['editAccount'] && (
                  <Button
                    onClick={() => {
                      setShowAddNew(true);
                      setShowViewModal(false);
                    }}
                    variant="contained"
                    color="primary"
                    disableElevation
                    size='small'
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className="account-details" sx={{ minWidth: '600px' }}>
          <Grid container spacing={3}>

            <Grid item xs={12}
              sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4} >
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Eijent Id:
                  </Box>
                </Grid>
                <Grid item xs={12} md>
                  <Box className="small-subtitle primary-text ">
                    {accountToBeEdited?.uniqueEijentId}
                  </Box>
                </Grid>
              </Grid>
            </Grid> 

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Account Name <span className="required">*</span>:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text">
                    {accountToBeEdited?.name}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    State:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text">
                    {accountToBeEdited?.state && accountToBeEdited.state[0] ? accountToBeEdited.state[0]?.name : ''}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Projects:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="long-text" style={{ maxWidth: '80%' }}>
                      {' '}
                      {accountToBeEdited?.projectIds &&
                        accountToBeEdited?.projectIds?.length > 0
                        ? accountToBeEdited?.projectIds[0]?.eventName
                        : ''}
                    </span>
                    {accountToBeEdited?.projectIds && accountToBeEdited?.projectIds?.length > 1 && (
                      <div className="detail-popup">
                        <span className="count">
                          +{accountToBeEdited?.projectIds.length - 1}
                        </span>
                        <div className="info">
                          {showRestOfProjects(accountToBeEdited?.projectIds).map(
                            (projectIds: Project, i: number) => {
                              return (
                                <div key={i}>
                                  <p>{projectIds.eventName}</p>
                                </div>
                              );
                            },
                          )}
                        </div>
                      </div>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={commentSectionOpened ? 12 : 6} lg={commentSectionOpened ? 6 : 4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="small-subtitle bold primary-text">
                    Source:
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="small-subtitle primary-text">
                    {accountToBeEdited?.source === 'aiAgent'
                      ? 'AI Agent'
                      : accountToBeEdited?.source === 'imported'
                        ? 'Imported'
                        : 'Manually Added'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {customFields.map((cf: CustomField) => {
              console.log("cf----->",cf)
              return (
                <Grid item xs={12} key={cf._id}
                  sm={
                    cf.fieldData.fieldType === 'longText' ? 12 :
                      commentSectionOpened ? 12 : 6
                  }
                  lg={
                    cf.fieldData.fieldType === 'longText' ? 12 :
                      commentSectionOpened ? 6 : 4
                  }
                >
                  <Grid container>
                    <Grid item xs={12} >
                      <Box className="small-subtitle bold primary-text">
                        {cf.fieldData.fieldName}:
                      </Box>
                    </Grid>
                    <Grid item xs={12} >
                      <Stack direction={'row'} spacing={1}>
                        <Box
                          className={`small-subtitle primary-text  ${['contact', 'url', 'email', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType) ? 'link' : ''
                            }
                          ${cf.fieldData.fieldType === 'longText' ? '' : ''
                            }`}
                          // onClick={(e: SyntheticEvent<Element, Event>) => {
                          //   if (cf.fieldData.fieldType === 'contact') {
                          //     handleShowSideModal(
                          //       accountToBeEdited?.customFields?.[
                          //       cf._id as string
                          //       ] as string,
                          //       cf.fieldData.fieldName,
                          //     );
                          //   }
                          //   if (['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)) {
                          //     let url = (accountToBeEdited
                          //       ?.customFields?.[
                          //       cf._id as string
                          //     ] as string);
                          //     if (!/^https?:\/\//i.test(url)) {
                          //       // If not, prepend 'https://'
                          //       url = 'https://' + url;
                          //     }
                          //     window.open(url, "_blank");
                          //   }
                          //   if (cf.fieldData.fieldType === 'email') {
                          //     window.open(`mailto:${accountToBeEdited
                          //       ?.customFields?.[
                          //       cf._id as string
                          //     ] as string}`);
                          //   } else if (cf.fieldData.fieldType === 'phone') {
                          //     window.open(`tel:${accountToBeEdited
                          //       ?.customFields?.[
                          //       cf._id as string
                          //     ] as string}`);
                          //   }

                          // }}
                        >
                          {/* @ts-ignore */}
                          {customFieldValue(cf, accountToBeEdited)}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <ContactOrAccountDetailsSideModal
            open={openSideModal}
            handleClose={handleCloseSideModal}
            details={contactDetailsForToolTip}
            customFields={contactsCustomFields}
            usedFor={'contact'}
            title={titleForSideModal}
          />
        </Box>
      </Box>
      {(isCustomFieldsLoading || isAccountsLoading) && <Loader />}
    </>
  );
}

export default AccountView;
